import React from "react";

import {
  Paper,
  Container,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Scrollbar from "src/components/Scrollbar";

function CollectionTable({ date }) {
  console.log("HAS DATE ARRIVED", date);

  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );
  return (
    <div>
      {!isLoading && (
        <Paper sx={{ margin: "20px", padding: "15px" }}>
          <CardHeader title="Order wise Collection Details" sx={{ mb: 3 }} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.no</TableCell>
                    <TableCell>Order No.</TableCell>
                    <TableCell>Collection Agent</TableCell>
                    {/* <TableCell>Order Status</TableCell> */}
                    <TableCell>Collection Status</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Scheduled at</TableCell>
                    <TableCell>Collected at</TableCell>
                    <TableCell>Collected Time</TableCell>
                    <TableCell>Ticket Name</TableCell>
                    <TableCell>Total Containers</TableCell>
                    <TableCell>Collected</TableCell>
                    <TableCell>Not Collected</TableCell>
                    <TableCell>Missing</TableCell>
                    <TableCell>Status Not Updated</TableCell>
                    <TableCell>Extra Collection</TableCell>
                    <TableCell>Total Collected</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Customer Phone</TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  {data.CollectionAgentData.map((collection, index) => (
                    <TableRow>
                      <TableCell>{index + 1}.</TableCell>
                      <TableCell>{collection.order_no}</TableCell>
                      <TableCell>{collection.collectionAgent}</TableCell>
                      {/* <TableCell>{collection.order_status}</TableCell> */}
                      <TableCell>{collection.collection_status}</TableCell>
                      <TableCell>{collection.customer_name}</TableCell>
                      <TableCell>{collection.scheduled_slot}</TableCell>
                      <TableCell>{collection.collected_at}</TableCell>
                      <TableCell>{collection.order_collected_at}</TableCell>
                      <TableCell>{collection.ticket_name}</TableCell>
                      <TableCell>{collection.total_order_containers}</TableCell>
                      <TableCell>{collection.collected}</TableCell>
                      <TableCell>{collection.not_collected}</TableCell>
                      <TableCell>{collection.missing_from_customer}</TableCell>
                      <TableCell>{collection.not_updated}</TableCell>
                      <TableCell>{collection.extra_containers}</TableCell>
                      <TableCell>
                        {collection.total_containers_collected}
                      </TableCell>
                      <TableCell>{collection.created_at}</TableCell>
                      <TableCell>{collection.customer_phone}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>
      )}

      {!isLoading && (
        <Paper sx={{ margin: "25px", padding: "15px" }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>
                      {format(new Date(date), "dd-MM-yyyy")}
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Scheduled</TableCell>
                    <TableCell>{data.totalScheduled}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Scheduled Collected</TableCell>
                    <TableCell>{data.totalScheduledCollected}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Total Rescheduled </TableCell>
                    <TableCell>{data.rescheduled}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total in scheduled state</TableCell>
                    <TableCell>{data.scheduled}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total missed</TableCell>
                    <TableCell>{data.missedAll}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Rescheduled in ticket</TableCell>
                    <TableCell>{data.ticketReschedule}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Cancelled pickup</TableCell>
                    <TableCell>{data.ticketCancel_pickup}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>
      )}
    </div>
  );
}

export default CollectionTable;
