// components
import { useState, useEffect } from "react";
import Page from "../../components/Page";
import * as React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useLocation } from "react-router-dom";
import useSettings from "src/hooks/useSettings";
import { getTicketDetails } from "src/service/api";
import { Box, CircularProgress } from "@mui/material";
import Label from "src/components/Label";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";

// function getImage(body1) {
//   let s = body1.match(/(https?:\/\/[^ ]*)/);
//   var str = null;
//   if (s != null) {
//     str = s[0];
//   }
//   let img_full = "";
//   console.log(str);
//   if (str != null) {
//     for (var i = 0; i < str.length - 1; i++) {
//       img_full += str[i];
//     }
//     return img_full;
//   }
// }
export default function IssueDetails() {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { themeStretch } = useSettings();
  const location = useLocation();
  const ticket = location.state?.ticket;

  useEffect(() => {
    const fetchTicket = async () => {
      let res = await getTicketDetails(ticket.order_id, ticket.ticket_no);

      setDetails(res.data);
      setLoading(false);
    };

    fetchTicket();
  }, []);

  return (
    <div>
      <Page title="Batch Details">
        {!loading ? (
          <Container maxWidth={themeStretch ? false : "xl"}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              {`Ticket ${ticket.ticket_no} Details`}
            </Typography>

            <Stack direction="row" spacing={6}>
              <Card sx={{ width: 500, mb: 5 }}>
                <Box sx={{ padding: "2rem", mb: 3 }}>
                  <Label
                    color="error"
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      marginBottom: "20px",
                    }}
                  >
                    {` ${ticket.ticket_name}`}
                  </Label>

                  <Typography gutterBottom variant="h5" component="div">
                    {`Order Number : ${ticket.order_no}`}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    {`Ticket Created at : ${ticket.created_at}`}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    {`Collection Agent : ${ticket.collection_agent}`}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    {`Description (if any) : ${ticket.description}`}
                  </Typography>
                </Box>
              </Card>

              {details.rescheduleTime && (
                <Card sx={{ width: 300, height: 280, mb: 5 }}>
                  <Box sx={{ marginTop: "1rem", padding: "2rem", mb: 3 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      <Label
                        color="warning"
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          marginBottom: "10px",
                        }}
                      >
                        Collected Time:
                      </Label>
                      <br />

                      {details.collectedTime == null
                        ? "Not Collected"
                        : details.collectedTime}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ marginTop: "10px" }}
                    >
                      <Label
                        color="warning"
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          marginTop: "40px",
                          marginBottom: "10px",
                        }}
                      >
                        Rescheduled Time:
                      </Label>
                      <br />

                      {details.rescheduleTime}
                    </Typography>
                  </Box>
                </Card>
              )}
            </Stack>

            <Card sx={{ width: 500, mb: 5 }}>
              <Box sx={{ padding: "2rem", mb: 3 }}>
                <Typography gutterBottom variant="h5" component="div">
                  Order Containers:
                </Typography>
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: "10px", border: 0.5 }}
                >
                  {details.orderContainers.map((container) => (
                    <Box
                      m={1.5}
                      p={1}
                      sx={{
                        padding: "10px",
                        border: 1,
                        borderRadius: "16px",
                        borderColor: "red",
                      }}
                    >
                      {container.identifier}
                    </Box>
                  ))}
                </Grid>
                {details.ticketContainers && (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ marginTop: "15px" }}
                    >
                      Ticket Containers:
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      sx={{ marginTop: "10px", border: 0.5 }}
                    >
                      {details.ticketContainers.map((container) => (
                        <Box
                          m={1.5}
                          p={1}
                          sx={{
                            padding: "10px",
                            border: 1,
                            borderRadius: "16px",
                            borderColor: "red",
                          }}
                        >
                          {container.identifier}
                        </Box>
                      ))}
                    </Grid>
                  </>
                )}

                {details.containerType && (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ marginTop: "15px" }}
                    >
                      QR Damage Container Type:
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      sx={{ marginTop: "10px", border: 0.5 }}
                    >
                      {details.containerType.map((container) => (
                        <Box
                          m={1.5}
                          p={1}
                          sx={{
                            padding: "10px",
                            border: 1,
                            borderRadius: "16px",
                            borderColor: "red",
                          }}
                        >
                          {container.name}
                        </Box>
                      ))}
                    </Grid>
                  </>
                )}
              </Box>
            </Card>

            {/* <Typography
            variant="body2"
            color="text.secondary"
            style={{ wordBreak: "break-word" }}
          >
            <h3>Description:</h3>
            <div dangerouslySetInnerHTML={{ __html: issue.body }} />
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            style={{ wordBreak: "break-word" }}
          ></Typography>  */}
          </Container>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress
              value={100}
              size={56}
              thickness={4}
              sx={{ position: "absolute", top: "50%", left: "60%" }}
              disableShrink
            />
          </Box>
        )}
      </Page>
    </div>
  );
}
