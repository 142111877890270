import { Grid } from '@mui/material';
import React,{useState, useEffect} from 'react'
import WidgetSummary from '../../components/data-grid/WidgetSummary';
import { GetGridata } from 'src/service/api';
import { truncate } from 'lodash';
import { AnalyticsWidgetSummary } from 'src/sections/@dashboard/general/analytics';
import { Container } from '@mui/system';

function MetricsCards({analysis, page}) {
   
    const [data, setDtata] = useState(null)
    const [icon, setIcon] = useState("carbon:delivery-parcel")
    
    useEffect(() => {
      console.log({"analysis":analysis})
      setDtata(analysis)
    }, [analysis])
    
  return (
    <Container>
     <div style={{ display:"flex", flexDirection:'row', flexWrap:"wrap", alignItems: "center", justifyContent: "center", marginTop: 10 }}>

        {data !== null && 
      <Grid style={{margin:"auto"}} item xs={12} sm={6} md={2} >
            <AnalyticsWidgetSummary title="Total Orders Served" total={data.orderServed} icon={icon}></AnalyticsWidgetSummary>
        </Grid>}
        {data !== null && 
      <Grid style={{margin:"auto"}} item xs={12} sm={6} md={2} >
            <AnalyticsWidgetSummary title="Total Containers Sent" total={data.containerSent} icon={"bi:box-fill"}></AnalyticsWidgetSummary>
        
        </Grid>}
        {data !== null && 
      <Grid style={{margin:"auto"}}  item xs={12} sm={6} md={3} >
            <AnalyticsWidgetSummary  title="Containers Collected Back" total={data.containerCollected} subText={"% of Containers Sent"} subTitle={(data.containerCollected*100/data.containerSent).toFixed(2)} icon={"akar-icons:check-box-fill"}></AnalyticsWidgetSummary>
        
        </Grid>}
        
        {data !== null && 
      <Grid style={{margin:"auto"}}  item xs={12} sm={6} md={2} >
            <AnalyticsWidgetSummary title="Average Container Per Order" total={(data.avgContainerPerOrder).toFixed(3)} icon={"icon-park-outline:delivery"}></AnalyticsWidgetSummary>
        </Grid>}
        
        {data !== null && 
      <Grid style={{margin:"auto"}} item xs={12} sm={6} md={2} >
            <AnalyticsWidgetSummary title="Median time for Pickup (Hours)" total={data.median} icon={'ant-design:field-time-outlined'}></AnalyticsWidgetSummary>
        </Grid>}

    </div>
    </Container>
  )
}

export default MetricsCards