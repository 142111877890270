import React from "react";
import { Grid, Box, Typography, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import WidgetSummary from "src/components/data-grid/WidgetSummary";

function OrdersCountTimeSlot() {
  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );

  return (
    <>
      {!isLoading && (
        <div>
          <Container style={{ marginTop: 40 }}>
            <Typography
              variant="h5"
              component="h2"
              style={{ marginBottom: 10 }}
            >
              Time Slot Order's count
            </Typography>
          </Container>
          <Grid
            container
            spacing={2}
            wrap={"wrap"}
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            {data.timeSlot.map((slot, index) => (
              <Grid container item xs={6} sm={6} md={3} className="gridsm">
                <WidgetSummary
                  title={slot}
                  total={data.totalOrdersCollectedByTime[index]}
                  stotal={data.totalOrdersToBeCollectedByTime[index]}
                  icon={"bx:time"}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
}

export default OrdersCountTimeSlot;
