import { Suspense, lazy, useState, useEffect } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOADSHARE, PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import OrderListing from "src/pages/dashboard/OrderListing";

import OrderVerificationReport from "src/pages/dashboard/OrderVerificationReport";
import WeeklyReport from "src/pages/dashboard/WeeklyReport";
import CollectionAgentReport from "src/pages/dashboard/CollectionAgentReport/CollectionAgentReport";
import Issues from "src/pages/dashboard/Issues";
import IssueDetails from "src/pages/dashboard/IssueDetails";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [menu, setMenu] = useState(localStorage.getItem("menu"));

  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              // to={user === "9" ? PATH_AFTER_LOADSHARE : PATH_AFTER_LOGIN}
              to={PATH_AFTER_LOGIN}
              replace
            />
          ),
          index: true,
        },
        { path: "dashboard", element: <DashboardTypes /> },
        { path: "alerts", element: <Alerts /> },
        { path: "dispatch", element: <Dispatch /> },
        { path: "collectionSheet", element: <CollectionSheet /> },
        { path: "continerCountList", element: <ContinerCountList /> },
        { path: "eodReport", element: <EodReport /> },
        { path: "ledger", element: <Ledger /> },
        { path: "inventory", element: <Inventory /> },
        { path: "app", element: <GeneralApp /> },
        { path: "ecommerce", element: <GeneralEcommerce /> },
        { path: "analytics", element: <GeneralAnalytics /> },
        { path: "banking", element: <GeneralBanking /> },
        { path: "booking", element: <GeneralBooking /> },
        { path: "auth-tokens", element: <AuthTokens /> },
        { path: "order-listing", element: <OrderListing /> },
        { path: "overview", element: <Overview /> },
        { path: "metrics", element: <Metrics /> },
        { path: "history", element: <History /> },
        { path: "insights", element: <Insights /> },
        { path: "order-verification", element: <OrderVerificationReport /> },
        { path: "weekly-report", element: <WeeklyReport /> },
        { path: "issues", element: <Issues /> },
        { path: "issues/:issueID", element: <IssueDetails /> },
        { path: "collectionAgent-report", element: <CollectionAgentReport /> },
        { path: "mamaketo", element: <MamaKeto /> },
        { path: "feedback", element: <Feedback /> },

        {
          path: "batchListing",
          element: <BatchListing />,
        },
        {
          path: "batchListing/:batchId",
          element: <CreateBatch />,
        },
        { path: "collectContainers", element: <ReceiveBatch /> },
        { path: "collectContainers/new", element: <CollectContainers /> },
        // { path: "collectContainers", element: <CollectContainers /> },
        { path: "markAvailable", element: <MarkAvailable /> },

        { path: "notScheduled", element: <NotScheduled /> },

        { path: "b2bBatch", element: <B2BBatch /> },
        { path: "b2bBatch/:batchID", element: <B2BBatchContainers /> },
        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShop /> },
            { path: "product/:name", element: <EcommerceProductDetails /> },
            { path: "list", element: <EcommerceProductList /> },
            { path: "product/new", element: <EcommerceProductCreate /> },
            { path: "product/:name/edit", element: <EcommerceProductCreate /> },
            { path: "checkout", element: <EcommerceCheckout /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "cards", element: <UserCards /> },
            { path: "list", element: <UserList /> },
            { path: "new", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceList /> },
            { path: ":id", element: <InvoiceDetails /> },
            { path: ":id/edit", element: <InvoiceEdit /> },
            { path: "new", element: <InvoiceCreate /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPosts /> },
            { path: "post/:title", element: <BlogPost /> },
            { path: "new", element: <BlogNewPost /> },
          ],
        },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        { path: "calendar", element: <Calendar /> },
        { path: "kanban", element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "about-us", element: <About /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD
const DashboardTypes = Loadable(
  lazy(() => import("../pages/dashboard/DashboardTypes"))
);
const Inventory = Loadable(lazy(() => import("../pages/dashboard/Inventory")));
const Alerts = Loadable(lazy(() => import("../pages/Inventory/Alerts")));
const Dispatch = Loadable(
  lazy(() => import("../pages/dashboard/OrderDispatch"))
);
const CollectionSheet = Loadable(
  lazy(() => import("../pages/dashboard/collectionSheet"))
);
const ContinerCountList = Loadable(
  lazy(() => import("../pages/dashboard/ContinerCountList"))
);
const EodReport = Loadable(lazy(() => import("../pages/Inventory/EodReports")));
const Ledger = Loadable(lazy(() => import("../pages/Inventory/Ledger")));
const Overview = Loadable(lazy(() => import("../pages/Reporting/Overview")));
const Metrics = Loadable(lazy(() => import("../pages/Reporting/Consolidated")));
const History = Loadable(
  lazy(() => import("../pages/Reporting/ContainerHistory"))
);
const Insights = Loadable(
  lazy(() => import("../pages/Reporting/ContainerInsights"))
);
const MamaKeto = Loadable(lazy(() => import("../pages/dashboard/MamaKeto")));
const Feedback = Loadable(lazy(() => import("../pages/dashboard/Feedback")));

const CreateBatch = Loadable(
  lazy(() => import("../pages/dashboard/CreateBatch"))
);

const BatchListing = Loadable(
  lazy(() => import("../pages/dashboard/BatchListing"))
);

const ReceiveBatch = Loadable(
  lazy(() => import("../pages/dashboard/ReceiveBatch"))
);

const CollectContainers = Loadable(
  lazy(() => import("../pages/dashboard/CollectContainers"))
);

const MarkAvailable = Loadable(
  lazy(() => import("../pages/dashboard/MarkAvailable"))
);

const NotScheduled = Loadable(
  lazy(() => import("../pages/dashboard/NotScheduled")))

const B2BBatch = Loadable(lazy(() => import("../pages/dashboard/B2BBatch")));

const B2BBatchContainers = Loadable(
  lazy(() => import("../pages/dashboard/B2BBatchContainers"))
);

// const HelloWorld = Loadable(
//   lazy(() => import("../pages/dashboard/HelloWorld"))
// );
// AUTH TOKENS

const AuthTokens = Loadable(
  lazy(() => import("../pages/dashboard/AuthTokens"))
);

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBooking"))
);

// ECOMMERCE
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductList"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);

// INVOICE
const InvoiceList = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceList"))
);
const InvoiceDetails = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceDetails"))
);
const InvoiceCreate = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceCreate"))
);
const InvoiceEdit = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceEdit"))
);

// BLOG
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);

// USER
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);

// APP
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));

// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
