import axios from "../utils/axios";
import { Octokit } from "@octokit/rest";

const {
  axiosInstance,
  axiosMamaketoInstance,
  axiosInstanceInventory,
  axiosInstanceReporting,
  axiosInstanceStageV2,
} = axios;

export const getRecentOrders = async (id) => {
  try {
    const sortField = "id";
    const sortOrder = "desc";
    let response = await axiosInstance.post(`/orders/recent-orders`, {
      sortField,
      sortOrder,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling recent-orders api", error);
  }
};
export const getSyncGoogleSheet = async () => {
  try {
    let response = await axiosInstance.get(`/report/sync-google-sheet`);
    return response.data;
  } catch (error) {
    console.log("Error while calling sync-google-sheet api", error);
  }
};
export const getAnalysisReportsPlatform = async (id) => {
  try {
    let response = await axiosInstance.post(`/reports/analysis-report`, {
      from_date: "2022-06-10",
      to_date: "2022-07-12",
      restaurant: id,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling analysis-report api", error);
  }
};
export const getAnalysisReports = async (fromDate, toDate, resid) => {
  try {
    console.log(resid);
    let response = await axiosInstance.post(`/reports/analysis-report`, {
      from_date: fromDate,
      to_date: toDate,
      restaurant: resid,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling analysis-report api", error);
  }
};
export const getAdoption = async (fromDate, toDate, resid, view) => {
  try {
    // console.log('HELLO ',resid, fromDate, toDate)
    let response = await axiosInstance.post(`/report/weekly-adoption`, {
      from_date: fromDate,
      to_date: toDate,
      group: view,
      restaurant: resid,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling weekly-adoption api", error);
  }
};
export const getCollection = async (fromDate, toDate, resid, view) => {
  try {
    console.log(resid);

    let response = await axiosInstance.post(`/report/weekly-collection`, {
      from_date: fromDate,
      to_date: toDate,
      group: view,
      restaurant: resid,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling weekly-collection api", error);
  }
};

export const getAllOrders = async (pages) => {
  try {
    let allresponse = await axiosInstance.get(`/orders/all-orders/${pages}`);
    return allresponse.data;
  } catch (error) {
    console.log("Error while calling all-orders api", error);
  }
};
export const orderVerfication = async (pages) => {
  try {
    let allresponse = await axiosInstance.post(`/reports/order-verification`);
    return allresponse.data;
  } catch (error) {
    console.log("Error while calling order-verification api", error);
  }
};
export const weeklyReport = async (week) => {
  try {
    console.log("hey");
    let allresponse = await axiosInstance.post(`/reports/weekly-report`);
    return allresponse.data;
  } catch (error) {
    console.log("Error while calling weekly-report api", error);
  }
};
export const collectionReport = async (agent, dateValue) => {
  console.log("hello");
  try {
    let data = {
      filter: {
        agentname: "",
        scheduled_at: "",
      },
      pageNumber: 1,
      pageSize: 100,
    };
    data.filter.agentname = agent;
    data.filter.scheduled_at = dateValue;
    console.log(data);
    let collectionresponse = await axiosInstance.post(
      `/collection-report/agent-report`,
      data
    );
    return collectionresponse.data;
  } catch (error) {
    console.log("Error while calling collectionReport api", error);
  }
};

export const collectionAgentType = async () => {
  try {
    let response = await axiosInstance.get(`/agents/all-agents`);
    return response.data;
  } catch (error) {
    console.log("Error while calling all-agents api", error);
  }
};

export const totalOrders = async (id) => {
  try {
    let response = await axiosInstance.get(`/orders/total-orders`);
    return response.data;
  } catch (error) {
    console.log("Error while calling total-orders api", error);
  }
};

export const restaurantOverview = async () => {
  try {
    let response = await axiosInstanceInventory.get("/restaurant-overview");
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const currentRestaurantData = async (currRestaurant) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/restaurant-details?rest_id=${currRestaurant}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant-details API");
  }
};
export const allAgents = async () => {
  try {
    let response = await axiosInstanceInventory.get(`/all-agents`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const getRestaurantContainer = async () => {
  try {
    let response = await axiosInstance.get(
      `inventory/restaurantInventoryCount`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const getDayWiseRestaurant = async (day) => {
  try {
    let response = await axiosInstance.post(
      `inventory/getDaywiseRestaurantData`,
      {
        day,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const escalationAgentForAlerts = async (alertID) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/get-escalation-agent-for-alerts?id=${alertID}`
    );
    return response.data.agents ? response.data.agents : [];
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const minQuantity = async (restID, skuType) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/min-quantity?rest_id=${restID}&sku_type=${skuType}`
    );
    return response.data.min_quantity;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const reorderPoint = async ({ reorderPoint, restID, skuType }) => {
  try {
    const response = await axiosInstanceInventory.post("/user", {
      restID,
      skuType,
      reorderPoint,
    });
    return response.data.min_quantity;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const eodType = async () => {
  try {
    let response = await axiosInstanceInventory.get(`/eod-type`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const stakeholderType = async (ledgerType) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/eod-stakeholder-type?id=${ledgerType}`
    );
    return response.data.filter;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const getEodData = async ({
  ledgerType,
  stakeholder,
  selectedDate,
  name,
}) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/eod-data?stakeholder_type=${ledgerType}&stakeholder_name=${name}&stakeholder_filter=${stakeholder}&date=${selectedDate
        .utc()
        .format()}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const getLedgerType = async () => {
  try {
    let response = await axiosInstanceInventory.get(`/ledger-type`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching ledger Types");
  }
};
export const getLedgerData = async ({
  ledgerType,
  stakeholder,
  fromDate,
  toDate,
}) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/ledger-data?stakeholder_type=${ledgerType}&stakeholder_id=${stakeholder}&from_date=${fromDate}&to_date=${toDate}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching Ledger Data");
  }
};

export const allContainer = async () => {
  try {
    let t_response = await axiosInstance.get(`/inventory/sku`);
    return t_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const minContainer = async () => {
  try {
    let m_response = await axiosInstance.get(`/inventory/mincount`);
    return m_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const totalSentContainers = async () => {
  try {
    let s_response = await axiosInstance.get(`/inventory/sentcontainer`);
    return s_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const totalReceivedContainers = async (id) => {
  try {
    let r_response = await axiosInstance.get(`/inventory/received`);
    return r_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const batchHistory = async () => {
  try {
    let b_response = await axiosInstance.post(`/batch-history/batches`);
    return b_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const getTotalSku = async () => {
  try {
    const response = await axiosInstanceReporting.get(`/sku`);
    console.log({ response: response.data });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const containerSummary = async (skuType, summaryContainer) => {
  try {
    const response = await axiosInstanceReporting.get(
      `/summary_data?id=${skuType}&opType=${summaryContainer}`
    );
    return response.data[0];
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const containerSummaryDetail = async (contID, skuType) => {
  try {
    const response = await axiosInstanceReporting.get(
      `/summary_container?opType=${contID}&id=${skuType}`
    );
    return response.data[0];
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const GetMetrics = async (id, skuType) => {
  try {
    const response = await axiosInstanceReporting.get(
      `/metrics?id=${skuType}&opType=${id}`
    );
    return response.data.value;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const GetConsolidatedMetrics = async () => {
  try {
    const response = await axiosInstanceReporting.get(`/consolidated_metrics`);
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const GetGridata = async (
  Filter,
  filterName,
  pageNumber,
  pageSize,
  sortField,
  sortOrder,
  date,
  page
) => {
  let endpoint = "";
  switch (page) {
    case "order_dispatch":
      endpoint = "report/order-dispatch";
      break;
    case "collection_sheet":
      endpoint = "report/container-collection-list";
      break;
    case "container_count":
      endpoint = "report/restaurant-wise-container-count-list";
      break;
    case "weeklyReport":
      endpoint = `/reports/weekly-report`;
      break;
    case "orderVerification":
      endpoint = `/reports/order-verification`;
      break;
    case "orderListing":
      endpoint = `/orders/recent-orders`;
      break;

    default:
      break;
  }
  let dateFilter = "";
  switch (page) {
    case "order_dispatch":
      dateFilter = "created_at";
      break;
    case "collection_sheet":
      dateFilter = "Container_collection_date";
      break;
    case "container_count":
      dateFilter = "updated_at";
      break;
    case "orderVerification":
      dateFilter = "date";
      break;
    case "orderListing": {
      dateFilter = "scheduled_date";
      break;
    }

    default:
      dateFilter = "created_at";
      break;
  }
  try {
    if (filterName.length !== 0) {
      const filter = {};
      filter[Filter] = filterName;
      filter[dateFilter] = date;
      console.log({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        filter: filter,
      });
      const response = await axiosInstance.post(endpoint, {
        filter,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
      });
      return response.data;
    } else {
      const filter = {};
      filter[dateFilter] = date;
      console.log({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        filter: filter,
      });
      const response = await axiosInstance.post(endpoint, {
        filter,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
      });
      return response.data;
    }
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const getChartDailyData = async (month, year) => {
  try {
    let response = await axiosInstance.get(
      `/orders/chartDaywiseData/${month}/${year}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const getChartData = async () => {
  try {
    let response = await axiosInstance.get(`/orders/chartMonthlyData`);
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

//mamaketo
export const postDispatchValidation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(`csv/csvDispatchValidate`, {
      data: file,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postCollectionValidation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `csv/csvCollectionValidate`,
      { file }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postDispatchCreation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(`b2b/b2bDispatchContainer`, {
      file,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postCollectionCreation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(`b2b/b2bCollectContainer`, {
      file,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postAccessToken = async (data) => {
  try {
    console.log("data:", data.username);
    let username = data.username;
    let password = data.password;
    let response = await axiosInstanceStageV2.post(`oauth/access_token`, {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};

// Issues
export const getIssues = async (direction, sort, state, labels) => {
  const octokit = new Octokit({
    auth: "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",
  });
  let total_response = [];
  let i = 1;
  let response_length = 10;
  while (response_length === 10) {
    let res = await octokit.request(
      "GET /repos/ShreyasGeetha/GitIssueLearning/issues",
      {
        owner: "ShreyasGeetha",
        repo: "GitIssueLearning",
        direction: direction,
        sort: sort,
        state: state,
        labels: labels,
        per_page: 10,
        page: i,
      }
    );
    response_length = res.data.length;
    i += 1;
    total_response.push(...res.data);
  }
  console.log(total_response);

  return total_response;
};

//       console.log(total_response);

//       return total_response;
// }

// Container Types

export const addContainerType = async (ContainerName, ContainerStatus) => {
  const ContainerTypeJson = {
    container_type: ContainerName,
    status: ContainerStatus,
  };

  try {
    let response = await axiosInstance.post(
      `containerTypes/addContainerType`,
      ContainerTypeJson
    );
    return response.data;
  } catch (error) {
    console.log("Error while add Container Type", error);
  }
};

export const getContainerTypes = async () => {
  try {
    let response = await axiosInstance.get(`containerTypes/getContainerTypes`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Container Types", error);
  }
};

export const updateContainerType = async (ContainerName, ContainerStatus) => {
  const ContainerTypeJson = {
    container_type: ContainerName,
    status: ContainerStatus,
  };

  try {
    let response = await axiosInstance.put(
      `containerTypes/updateContainerType`,
      ContainerTypeJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Container Types", error);
  }
};

export const deleteContainerType = async ({ ContainerName }) => {
  const ContainerTypeJson = {
    container_type: ContainerName,
  };

  try {
    console.log(ContainerTypeJson);
    let response = await axiosInstance.put(
      `containerTypes/deleteContainerType`,
      ContainerTypeJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while deleting Container Types", error);
  }
};

// Containers

export const addContainers = async (containerCount, containerTypeID) => {
  const containersJson = {
    containerCount: containerCount,
    containerTypeID: containerTypeID,
  };

  console.log(containersJson);

  try {
    let response = await axiosInstance.post(
      `containers/create-container-bulk`,
      containersJson
    );
    return response.data;
  } catch (error) {
    console.log("Error while add Container Type", error);
  }
};

export const getContainers = async () => {
  try {
    let response = await axiosInstance.get(`containers/getcontainers`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Container Types", error);
  }
};

export const getBatchListing = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`batch`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Batch Listing", error);
  }
};

export const getBatchDetails = async (id) => {
  try {
    let response = await axiosInstanceStageV2.get(`batch/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Batch Listing", error);
  }
};

export const getProvisionalContainers = async (id) => {
  try {
    let response = await axiosInstanceStageV2.get(
      `batch/provisional-containers/${id}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProvisionalContainers = async (containers, batchId) => {
  const submitBodyJson = {
    batch_id: batchId,
    containers: containers,
  };
  console.log(submitBodyJson);
  try {
    let response = await axiosInstanceStageV2.post(
      `batch/provisional-add-container-by-system`,
      submitBodyJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyContainers = async (containers, batchId) => {
  const submitBodyJson = {
    batch_id: batchId,
    containers: containers,
  };
  console.log(submitBodyJson);
  try {
    let response = await axiosInstanceStageV2.post(
      `batch/validate-batch-containers`,
      submitBodyJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProvisionCollectContainer = async (container, agent) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/addProvisionCollectContainerSystem`,
      {
        container: container,
        collection_agent: agent,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProvisionAvailableContainer = async (container) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/addProvisionCollectContainerSystem`,
      {
        container: container,
        collection_agent: "app-admin",
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const refreshContainers = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/refreshRedisContainers`
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const finalizeCollectContainers = async (agent) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/saveProvisionCollectContainerSystem`,
      {
        collection_agent: agent,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const markContainerAvailable = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/markContainerAvailable`,
      {
        collection_agent: "app-admin",
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getContainerStatus = async () => {
  try {
    let response = await axiosInstanceStageV2.get(
      `/containers/byStatusCount/In Washing Center`
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const receiveBatch = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/batch/receiveBatch`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getFeedback = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/feedback/getFeedbackDump`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get feedback api", error);
  }
};

export const getTicketType = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/ticket/getTicketType`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get ticket type api", error);
  }
};

export const getAllTicketDetails = async (
  fromDate,
  toDate,
  agent,
  ticket,
  orderNo
) => {
  try {
    let response = await axiosInstanceStageV2.get(
      `/ticket/getAllTicketDetails`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          collection_agent: agent,
          ticket_type: ticket,
          order_no: orderNo,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling get all ticket api", error);
  }
};

export const getTicketDetails = async (order_id, ticket_id) => {
  try {
    let response = await axiosInstanceStageV2.post(`/ticket/getTicketDetails`, {
      order_id: order_id,
      ticket_id: ticket_id,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling get ticket details api", error);
  }
};

export const getOrderInfo = async (fromDate, toDate) => {
  try {
    let response = await axiosInstance.get(
      `/restaurants/get-restaurant-order-information/`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling get order info api", error);
  }
};

export const getB2BStats = async () => {
  try {
    let response = await axiosInstance.post(
      `/report/daily-consumes-container-stats`,
      {
        filter: {},
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "asc",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b stats api", error);
  }
};

export const getB2BRestStats = async () => {
  try {
    let response = await axiosInstance.post(
      `/inventory/getDailyConsumesContainerStatDetails`,
      {
        platform_id: 1,
        stats_date: "2022-09-20",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b stats api", error);
  }
};

export const updateB2BStats = async () => {
  try {
    let response = await axiosInstance.post(
      `/inventory/addUpdateDailyConsumesContainerStats`,
      {
        platform_id: 1,
        stats_date: "2022-09-20",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b stats api", error);
  }
};



export const getRestaurantNames = async () => {
  try {
    let response = await axiosInstance.get(`/restaurants/getRestaurants`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get resto info api", error);
  }
};



export const b2bContainerCount = async (fromDate, toDate, restaurant) => {
  try {
    let b_response = await axiosInstance.get(
      `/batch-history/b2bContainersCount`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          restaurantName: restaurant,
        },
      }
    );
    return b_response.data;
  } catch (error) {
    console.log("Error while calling b2b container count api", error);
  }
};

export const getOrderReport = async (date) => {
  try {
    let response = await axiosInstance.get(`orders/notCreatedOrders`, {
      params: {
        searchDate: date,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling order report api", error);
  }
};

