import moment from "moment";
import Page from "../../../components/Page";
import { collectionAgentType } from "../../../service/api";
import {
  AppWidgetSummary,
  ContainerWidgetSummary,
} from "../../../sections/@dashboard/general/app";
import { useState, useEffect } from "react";
import * as React from "react";
import {
  Container,
  Grid,
  IconButton,
  Card,
  Button,
  Divider,
  MenuItem,
  CardHeader,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Box,
  CircularProgress,
} from "@mui/material";
import "src/styles/appAdminDashboard.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { CSVLink } from "react-csv";
import Iconify from "src/components/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { collectionReport } from "src/redux/slices/collectionAgentReportState";
import ContainersDetails from "./ContainersDetails";
import ContainersToCollect from "./ContainersToCollect";
import ContainersCountTimeSlot from "./ContainersCountTimeSlot";
import OrdersCountTimeSlot from "./OrdersCountTimeSlot";
import SearchIcon from "@mui/icons-material/Search";
import useSettings from "src/hooks/useSettings";
import CollectionTable from "./CollectionTable";
import LoadShareTable from "./LoadShareTable";

export default function Inventory() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const [collection, setCollection] = useState([]);
  const [collectionAgent, setCollectionAgent] = useState([]);
  const [agent, setAgent] = React.useState("");

  const { themeStretch } = useSettings();
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );

  const getCollectionAgentInfo = async () => {
    setLoading(true);
    const collectionDataResponse = await dispatch(
      collectionReport(
        agent,
        fromDate,
        toDate,
        localStorage.getItem("userTypeId"),
        moment().format("YYYY-MM-DD")
      )

    );
    setLoading(false);
  };

  useEffect(async () => {
    //getting collection agent menu on page load

    setUser(localStorage.getItem("userTypeId"));

    let collectionagent = await collectionAgentType();
    console.log("collectionAgent", collectionagent);
    setCollectionAgent(collectionagent);
    setLoading(false);
  }, []);

  useEffect(() => {
    console.log("Loading...", isLoading);
  }, [data]);

  const handleAgent = (event) => {
    setAgent(event.target.value);
  };

  return (
    <Page title="Collection Agent Report">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Collection Agent Report
        </Typography>
        {user === "9" ? (
          <LoadShareTable agent={agent} fromDate={fromDate} toDate={toDate} />
        ) : null}

        <div
          style={{
            marginTop: "3rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper elevation={10} className="search-bar">
            <div className="filterline1">
              <FormControl size={"small"} sx={{ minWidth: 200 }}>
                <InputLabel id="demo-simple-select-label">
                  Collection Agents
                </InputLabel>
                <Select
                  style={{ minWidth: 200, margin: 5 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={agent}
                  label="Age"
                  onChange={handleAgent}
                >
                  {collectionAgent.map((item) => (
                    <MenuItem value={item.username}>{item.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider
                className="divider"
                style={{ margin: 4 }}
                orientation="vertical"
              />

              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  //   onKeyPress={handleKeypress}
                  label="From Date"
                  inputFormat="dd/MM/yyyy"
                  value={fromDate}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("YYYY-MM-DD");

                    setFromDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="dd/MM/yyyy"
                  value={toDate}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("YYYY-MM-DD");
                    setToDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>

              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {data.CollectionAgentData && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CSVLink data={data.CollectionAgentData}>
                      <IconButton color="primary">
                        <Iconify icon={"eva:download-outline"} />
                      </IconButton>
                    </CSVLink>

                    <Typography style={{ fontSize: 11 }}>Download</Typography>
                  </div>
                )}
                <Divider
                  className="divider"
                  style={{ margin: 5 }}
                  orientation="vertical"
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 150,
                  }}
                >
                  <Button
                    className="searchButton"
                    style={{ margin: 5 }}
                    onClick={getCollectionAgentInfo}
                  >
                    <SearchIcon className="searchButtonIcon" />
                  </Button>
                  <Typography style={{ fontSize: 11 }}>
                    Click here to search
                  </Typography>
                </div>
              </div>
            </div>
          </Paper>
        </div>

        {!loading ? (
          <>
            {/* <ContainersDetails />
            <ContainersCountTimeSlot />
            <OrdersCountTimeSlot /> */}
            <CollectionTable date={toDate} />
            {/* <ContainersToCollect /> */}
      
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress
              value={100}
              size={56}
              thickness={4}
              sx={{ position: "absolute", top: "75%", left: "60%" }}
              disableShrink
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
