import {
  Container,
  TableRow,
  Button,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  Divider,
  Grid,
  Box,
  Card,
  Table,
  Switch,
  Tooltip,
  MenuItem,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from "@mui/material";

import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";
import { weeklyReport } from '../../service/api'
import DataGrid from "src/components/data-grid/DataGrid";

export default function OrderListing() {
  const { themeStretch } = useSettings();
  const columnArr = ['order_id',
'order_status',
'restaurant_name',
'customer_name',
'customer_phone',
'total_no_of_containers',
'container_type',
'collected_at',
'collection_status',]
  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
    <Page title="Swiggy Weekly">
        <h2 id="header">Swiggy Weekly Report</h2>
      <DataGrid page="weeklyReport" columnArr={columnArr} />
    </Page>
    </Container>
  );
}
