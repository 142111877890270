import {
  Container
} from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";

import DataGrid from "src/components/data-grid/DataGrid";

export default function OrderListing() {
  const { themeStretch } = useSettings();
  return (
    <Page title="IB Order Verification Report">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Order Verification"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
            { name: 'Order Listing', },
          ]}
        />
        <DataGrid page="orderVerification" />
      </Container>
    </Page>
  );
}
