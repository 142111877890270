import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const B2BMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;

  {
    subheader: "general",
    items: [
      {
        title: "Manual Data Entry",
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.analytics,
      },
      // {
      //   title: "Inventory",
      //   path: PATH_DASHBOARD.general.inventory,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     {
      //       title: "general",
      //       path: PATH_DASHBOARD.general.inventory,
      //       icon: ICONS.alerts,
      //     },
      //     {
      //       title: "eod reports",
      //       path: PATH_DASHBOARD.general.eodReport,
      //       icon: ICONS.eodReport,
      //     },
      //   ],
      // },
    ],
  },
];

export { B2BMenu };
