// components
import { useState, useEffect } from "react";
import Page from "../../components/Page";
import { getIssues, getTicketType } from "src/service/api";

import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {
  Container,
  Grid,
  Card,
  Button,
  Divider,
  MenuItem,
  CardHeader,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Box,
  CircularProgress,
  CardContent,
  ListItemText,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Link } from "react-router-dom";
import useSettings from "src/hooks/useSettings";
import { Pagination } from "@mui/lab";
import usePagination from "src/utils/Pagination.js";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getAllTicketDetails } from "./../../service/api";
import IssuesTable from "./IssuesTable";
import { collectionAgentType } from "./../../service/api";
import moment from "moment";
import { getTicketDetails } from "./../../service/api";

export default function Issues() {
  const { themeStretch } = useSettings();
  const [data, setData] = useState([]);
  const [agent, setAgent] = useState();
  const [collectionAgent, setCollectionAgent] = useState([]);
  const [tags, setTags] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [ticketTag, setTicketTag] = useState([]);
  const [state, setState] = useState("open");
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [order, setOrder] = useState([]);

  const [loading, setLoading] = useState(true);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const [count, setCount] = useState(0);
  const _DATA = usePagination(data, PER_PAGE);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 250,
      },
    },
  };

  // const handleChange = (e, p) => {
  //   setPage(p);
  //   _DATA.jump(p);
  // };

  useEffect(() => {
    console.log("getting data");
    getData();
  }, []);

  const loadData = async () => {
    console.log("search");
    console.log("WHAT TICKET TAG", ticketTag);

    setLoading(true);
    if (agent === 5) {
      let details = await getAllTicketDetails(fromDate, toDate);

      setData(details.data);
      setLoading(false);
    } else {
      let details = await getAllTicketDetails(
        fromDate,
        toDate,
        agent,
        ticketTag,
        order
      );
      console.log("TICKET DETAIL DATA", details);
      setData(details.data);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log("load data");
  //   loadData();
  // }, [fromDate, toDate, agent, ticket]);

  const getData = async () => {
    let res = await getTicketType();
    console.log("TICKET TYPE DATA", res);
    setTags(res.data);
    let collectionagent = await collectionAgentType();
    console.log("collectionAgent", collectionagent);
    setCollectionAgent(collectionagent);

    let details = await getAllTicketDetails(
      fromDate,
      toDate,
      agent,
      ticket,
      order
    );
    console.log("ALL TICKET DETAIL DATA", details);
    setData(details.data);

    setLoading(false);
  };

  const handleAgent = (event) => {
    let a = collectionAgent.filter(
      (value) => value.username == event.target.value
    );
    console.log("AGENT A", a[0]);
    setAgent(a[0].id);
  };

  const handleTicket = (event) => {
    setTicket(event.target.value);
    // let t = tags.filter((tag) => tag.ticket_name == event.target.value);

    let tagSelected = tags.filter((tag) =>
      event.target.value.includes(tag.ticket_name)
    );

    let tagId = tagSelected.map((tag) => tag.id);

    console.log("WHAT TICKET", tagId);

    setTicketTag(tagId);
  };

  // const handleState = (event) => {
  //   setState(event.target.value.toString());
  // };

  const handleOrder = (event) => {
    setOrder(event.target.value);
  };

  return (
    <Page title="Ticketing">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Ticketing
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <Paper elevation={10} className="search-bar">
            <div className="filterline1">
              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  //   onKeyPress={handleKeypress}
                  label="From Date"
                  inputFormat="dd/MM/yyyy"
                  value={fromDate}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("YYYY-MM-DD");

                    setFromDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="dd/MM/yyyy"
                  value={toDate}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("YYYY-MM-DD");
                    setToDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <FormControl
                size={"small"}
                sx={{ minWidth: 150 }}
                style={{ margin: 5 }}
              >
                <InputLabel id="demo-simple-select-label">
                  Collection Agent
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={agent}
                  label="Collection Agent"
                  onChange={handleAgent}
                >
                  {collectionAgent.map((item, id) => (
                    <MenuItem key={id} value={item.username}>
                      {item.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <FormControl
                size={"small"}
                sx={{ minWidth: 150 }}
                style={{ margin: 5 }}
              >
                <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ticket.ticket_name}
                  renderValue={(selected) => selected.join(", ")}
                  label="Tags"
                  onChange={handleTicket}
                >
                  {tags.map((tag, index) => (
                    <MenuItem key={index} value={tag.ticket_name}>
                      <Checkbox
                        key={index}
                        checked={isChecked == index}
                        onClick={() => isCheckboxChecked(index)}
                      />
                      <ListItemText primary={tag.ticket_name} />
                    </MenuItem>
                  ))}
                </Select> */}
                <Select
                  labelId="demo-multiple-checkbox-label"
                  style={{ maxWidth: 300 }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={ticket}
                  onChange={handleTicket}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag} value={tag.ticket_name}>
                      <Checkbox
                        checked={ticket.indexOf(tag.ticket_name) > -1}
                      />
                      <ListItemText primary={tag.ticket_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <FormControl
                size={"small"}
                sx={{ minWidth: 150 }}
                style={{ margin: 5 }}
              >
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state}
                  label="State"
                  onChange={handleState}
                >
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <MenuItem value="all">All</MenuItem>
                </Select>
              </FormControl> */}

              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />
              <div style={{ maxWidth: "15rem", margin: 5 }}>
                <TextField
                  size={"small"}
                  labelId="demo-simple-select-label"
                  style={{
                    // margin: "0rem 0.5rem 0rem 0.5rem",

                    width: "15rem",
                  }}
                  label="Order ID"
                  fullWidth
                  value={order.length ? order : ""}
                  onChange={handleOrder}
                  placeholder="Search"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 150,
                }}
              >
                <Button className="searchButton" onClick={loadData}>
                  <SearchIcon className="searchButtonIcon" />
                </Button>
                <Typography style={{ fontSize: 11 }}>
                  Click here to Filter
                </Typography>
              </div>
            </div>
          </Paper>
        </div>

        {!loading ? (
          <IssuesTable rows={data} />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress
              value={100}
              size={56}
              thickness={4}
              sx={{ position: "absolute", top: "50%", left: "60%" }}
              disableShrink
            />
          </Box>
        )}

        {/* <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {_DATA != null ? (
            _DATA.currentData().map((issue) => (
              <Grid
                noWrap
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                key={data.indexOf(issue)}
              >
                <Link
                  to={`/dashboard/issues/${issue.id}`}
                  state={{ issue: issue }}
                  style={{ textDecoration: "none" }}
                >
                  <Card style={{ backgroundColor: "#C8FACD" }}>
                    <CardHeader
                      style={{ wordBreak: "break-word" }}
                      title={`Title : ${issue.title}`}
                      subheader={`URL : ${issue.url}`}
                    />
                    <CardContent>
                      {issue.labels.map((label) => (
                        <Button
                          variant="outlined"
                          color="error"
                          style={{ marginLeft: 10 }}
                        >
                          {label.name}
                        </Button>
                      ))}
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))
          ) : (
            <div></div>
          )}
        </Grid>
        {_DATA != null ? (
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
              marginTop: 20,
            }}
          >
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        ) : (
          <div></div>
        )} */}
      </Container>
    </Page>
  );
}
