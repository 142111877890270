import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from "../../utils/axios";

const { axiosInstance } = axios;

export const collectionAgentReportSlice = createSlice({
  name: "collectionAgentReportData",
  initialState: {
    data: {
      totalContainersCollected: 0,
      totalContainersCollectedByTime: [],
      totalOrdersCollectedByTime: [],
    },
    isLoading: true,
  },
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    updateCollectionAgentReportSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});


export function collectionReport(agent, fromDate, toDate, user_type_id, date) {
  return async () => {
    dispatch(collectionAgentReportSlice.actions.startLoading());
    try {
      let data = {
        filter: {
          agentname: "",
          fromDate: "",
          toDate: "",
          user_type_id: "",
          date: "",
        },
        pageNumber: 1,
        pageSize: 100,
      };

      data.filter.agentname = agent ? agent : "All";
      data.filter.fromDate = fromDate;
      data.filter.toDate = toDate;
      data.filter.user_type_id = user_type_id;
      data.filter.date = date;


      console.log("collection agent data running", data);
      let collectionResponse = await axiosInstance.post(
        `/collection-report/agent-report`,
        data
      );


      console.log("collection agent response", collectionResponse.data);


      dispatch(
        collectionAgentReportSlice.actions.updateCollectionAgentReportSuccess(
          collectionResponse.data.data
        )
      );
    } catch (error) {
      dispatch(collectionAgentReportSlice.actions.hasError(error));
    }
  };
}

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } =
  collectionAgentReportSlice.actions;

export default collectionAgentReportSlice.reducer;
