import { B2BMenu } from "./B2BMenu";
import { loadShareMenu } from "./LoadShareMenu";

const { adminMenu } = require("./AdminMenu");
const { platformOwnerMenu } = require("./PlatformOwnerMenu");
const { restaurantOwnerMenu } = require("./RestaurantOwnerMenu");

const menuMapping = {
  1: {
    menuType: adminMenu,
  },
  2: {
    menuType: adminMenu,
  },
  3: {
    menuType: adminMenu,
  },
  4: {
    menuType: adminMenu,
  },
  5: {
    menuType: B2BMenu,
  },
  6: {
    menuType: adminMenu,
  },
  7: {
    menuType: restaurantOwnerMenu,
  },
  8: {
    menuType: platformOwnerMenu,
  },
  9: {
    menuType: loadShareMenu,
  },
};

function getMenu(menuType) {
  return menuMapping[menuType];
}

export { getMenu };
