import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from "../store";
import axios from '../../utils/axios';

import {navConfig, navOrder} from "../../../src/layouts/dashboard/navbar/NavConfig";
import { platformOwnerMenu } from 'src/layouts/dashboard/navbar/CustomizedNavBar/PlatformOwnerMenu';
import { restaurantOwnerMenu } from 'src/layouts/dashboard/navbar/CustomizedNavBar/RestaurantOwnerMenu';
import { getMenu } from 'src/layouts/dashboard/navbar/CustomizedNavBar/menuExport';
// import navOrder from "../../../src/layouts/dashboard/navbar/NavOrder";


const {axiosInstance} = axios

export const menuSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    navMenu: navConfig,
    isLoading: false,    
  },
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    updateMenuSuccess: (state, action) => {
      state.isLoading = false;
      state.navMenu = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },   
  },
})

export function getMenuType() {
  return async () => {
    dispatch(menuSlice.actions.startLoading());
    try {
      const response = await axiosInstance.get("/menu");      
      console.log(response.data)
      // console.log('what type of menu to be rendered =', response.data)
      // const menuType = (response.data.menuType == 'Order Dispatch') ?
      //   platformOwnerMenu : platformOwnerMenu
      const menu = getMenu(response.data.menuType)
      dispatch(menuSlice.actions.updateMenuSuccess(menu.menuType));
    } catch (error) {
      dispatch(menuSlice.actions.hasError(error));
    }
  };
}

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = menuSlice.actions

export default menuSlice.reducer