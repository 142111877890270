import React, { useContext, useEffect } from 'react'
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components


import ICONS from './NavIcons';
import Label from "../../../components/Label";

import SvgIconStyle from "../../../components/SvgIconStyle";


const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const muiIcon = (name) => (
  <SvgIconStyle src={"../../../assets/alert.svg"} sx={{ width: 1, height: 1 }} />
)


// const navConfig = [
//   // GENERAL
//   // ----------------------------------------------------------------------
//   // const { title, path, more, products, tags, children } = parent;

//   {
//     subheader: "general",
//     items: [
//       {
//         title: "Dashboard",
//         path: PATH_DASHBOARD.general.dashboard,
//         icon: ICONS.dashboard
//       },
//       {
//         title: "Inventory",
//         path: PATH_DASHBOARD.general.inventory,
//         icon: ICONS.ecommerce,
//         children: [
//           {
//             title: "general",
//             path: PATH_DASHBOARD.general.inventory,
//             icon: ICONS.alerts,
//           },
//           {
//             title: "alerts",
//             path: PATH_DASHBOARD.general.alerts,
//             icon: ICONS.alerts,
//           },
//           {
//             title: "eod reports",
//             path: PATH_DASHBOARD.general.eodReport,
//             icon: ICONS.eodReport,
//           },
//           {
//             title: "ledger",
//             path: PATH_DASHBOARD.general.ledger,
//             icon: ICONS.ledger,
//           },
//           {
//             title: "overview",
//             path: PATH_DASHBOARD.general.overview,
//             icon: ICONS.overview,
//           },
//           {
//             title: "metrics",
//             path: PATH_DASHBOARD.general.metrics,
//             icon: ICONS.metrics,
//           },
//           {
//             title: "history",
//             path: PATH_DASHBOARD.general.history,
//             icon: ICONS.history,
//           },
//           {
//             title: "insights",
//             path: PATH_DASHBOARD.general.insights,
//             icon: ICONS.insights,
//           },

//         ],
//       },
//       {
//         title: "Reports",
//         icon: ICONS.analytics,
//         children: [
//           {
//             title: "Swiggy Weekly Report",
//             path: PATH_DASHBOARD.general.weeklyReport
//           },
//           {
//             title: "Order Verficiation",
//             path: PATH_DASHBOARD.general.orderVerificationReport
//           },
//           {
//             title: "Collection Agent Report",
//             path: PATH_DASHBOARD.general.collectionReport
//           },
//         ],
//       },
//       {
//         title: "Order Listing",
//         path: PATH_DASHBOARD.general.orderListing,
//         icon: ICONS.analytics,
//       },
//       // {
//       //   title: "Invoice",
//       //   path: PATH_DASHBOARD.general.invoice,
//       //   icon: ICONS.banking,
//       // },
//       {
//         title: "auth tokens",
//         path: PATH_DASHBOARD.general.authtokens,
//         icon: ICONS.authtokens,
//       },
//       {
//         title: "alerts",
//         path: PATH_DASHBOARD.general.alerts,
//         icon: ICONS.alerts,
//       },
//       {
//         title: "eod reports",
//         path: PATH_DASHBOARD.general.eodReport,
//         icon: ICONS.eodReport,
//       },
//       {
//         title: "ledger",
//         path: PATH_DASHBOARD.general.ledger,
//         icon: ICONS.ledger,
//       },
//       {
//         title: "overview",
//         path: PATH_DASHBOARD.general.overview,
//         icon: ICONS.overview,
//       },
//       {
//         title: "metrics",
//         path: PATH_DASHBOARD.general.metrics,
//         icon: ICONS.metrics,
//       },
//       {
//         title: "history",
//         path: PATH_DASHBOARD.general.history,
//         icon: ICONS.history,
//       },
//       {
//         title: "insights",
//         path: PATH_DASHBOARD.general.insights,
//         icon: ICONS.insights,
//       },
//       {
//         title: "Order Verficiation",
//         path: PATH_DASHBOARD.general.orderVerificationReport,
//         icon: ICONS.analytics,
//       },
//       {
//         title: "Swiggy Weekly Report",
//         path: PATH_DASHBOARD.general.weeklyReport,
//         icon: ICONS.analytics,
//       },
//       {

//         title: "Order Dispatch",
//         path: PATH_DASHBOARD.general.dispatch,
//         icon: ICONS.grid,
//       },
//       {

//         title: "Collection Sheet from DB",
//         path: PATH_DASHBOARD.general.collectionSheet,
//         icon: ICONS.grid,
//       },
//       {
//         title: "Continer Count List",
//         path: PATH_DASHBOARD.general.continerCountList,
//         icon: ICONS.grid,
//         },
//         {
//         title: "Collection Agent Report",
//         path: PATH_DASHBOARD.general.collectionReport,
//         icon: ICONS.analytics,
//       },
//     ],
//   },
// ]

const navConfig = [
  // GENERAL
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard
      },
      {
        title: "Inventory",
        path: PATH_DASHBOARD.general.inventory,
        icon: ICONS.ecommerce,
        children: [
          {
            title: "general",
            path: PATH_DASHBOARD.general.inventory,
            icon: ICONS.alerts,
          },
          {
            title: "alerts",
            path: PATH_DASHBOARD.general.alerts,
            icon: ICONS.alerts,
          },
          {
            title: "eod reports",
            path: PATH_DASHBOARD.general.eodReport,
            icon: ICONS.eodReport,
          },
          {
            title: "ledger",
            path: PATH_DASHBOARD.general.ledger,
            icon: ICONS.ledger,
          },
          {
            title: "overview",
            path: PATH_DASHBOARD.general.overview,
            icon: ICONS.overview,
          },
          {
            title: "metrics",
            path: PATH_DASHBOARD.general.metrics,
            icon: ICONS.metrics,
          },
          {
            title: "history",
            path: PATH_DASHBOARD.general.history,
            icon: ICONS.history,
          },
          {
            title: "insights",
            path: PATH_DASHBOARD.general.insights,
            icon: ICONS.insights,
          },
        ],
      },
      {
        title: "Reports",
        icon: ICONS.analytics,
        children: [
          {
            title: "Collection Sheet from DB",
            path: PATH_DASHBOARD.general.collectionSheet,
            icon: ICONS.grid,
          },
          {
            title: "Continer Count List",
            path: PATH_DASHBOARD.general.continerCountList,
            icon: ICONS.grid,
          },
          {
            title: "Collection Agent Report",
            path: PATH_DASHBOARD.general.collectionReport
          },          
          {

            title: "Order Dispatch",
            path: PATH_DASHBOARD.general.dispatch,
            icon: ICONS.grid,
          },          
          {
            title: "Order Verficiation",
            path: PATH_DASHBOARD.general.orderVerificationReport
          },  
          {
            title: "Swiggy Weekly Report",
            path: PATH_DASHBOARD.general.weeklyReport
          },
        ],
      },
      {
        title: "Order Listing",
        path: PATH_DASHBOARD.general.orderListing,
        icon: ICONS.analytics,
      },     

      {
        title: "auth tokens",
        path: PATH_DASHBOARD.general.authtokens,
        icon: ICONS.authtokens,
      },
      {
        title: "Issues",
        path: PATH_DASHBOARD.general.issues,
        icon: ICONS.alerts,
      },  
      {

        title: "Order Dispatch",
        path: PATH_DASHBOARD.general.dispatch,
        icon: ICONS.grid,
      },
      {

        title: "Collection Sheet from DB",
        path: PATH_DASHBOARD.general.collectionSheet,
        icon: ICONS.grid,
      },
      {
        title: "Continer Count List",
        path: PATH_DASHBOARD.general.continerCountList,
        icon: ICONS.grid
      },
      {
        title: "Collection Agent Report",
        path: PATH_DASHBOARD.general.collectionReport,
        icon: ICONS.grid,
      },

    ],
  },

];

const navSwiggy = [
  {
    subheader: "general",
    items: [
      {
        title: "Swiggy Weekly Report",
        path: PATH_DASHBOARD.general.weeklyReport,
        icon: ICONS.analytics,
      },
    ]
  }
]

const navOrder = [
    {
      subheader: "general",
      items: [      
        {
        title: "Reports",
        icon: ICONS.analytics,
        children: [                    
          {

            title: "Order Dispatch",
            path: PATH_DASHBOARD.general.dispatch,
            icon: ICONS.grid,
          },                    
        ],
      },
      ]
    }
  ]


export {
  navConfig,
  navSwiggy,
  navOrder
};




