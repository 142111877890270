import axios from "../utils/axios";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
// utils
import { isValidToken, setSession } from "../utils/jwt";
import jwtDecode from "jwt-decode";
import { postAccessToken } from "src/service/api";

const { axiosInstance } = axios;

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const token = jwtDecode(accessToken);
          console.log("token", token);
          const response = await axiosInstance.get(`/users/${token.sub}`);
          const user = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    const response = await axiosInstance.post("/auth/login", {
      username,
      password,
    });
    const data = JSON.stringify({
      username: username,
      password: password,
    });

    const accessToken = await postAccessToken({
      username: username,
      password: password,
    });
    console.log({ accessToken: accessToken }, accessToken.data.access_token);
    localStorage.removeItem("accessToken");
    localStorage.setItem("accessToken", accessToken.data.access_token);
    localStorage.setItem("accessTokenv2", accessToken.data.access_token);
    const { tokens, user } = response.data;

    setSession(tokens.access.token);
    console.log("login data ", response); //add local storage
    localStorage.setItem("restaurantid", user.restaurant_id);
    localStorage.setItem("userTypeId", user.user_type_id);

    await dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const register = async (username, password, firstName, lastName) => {
    const response = await axiosInstance.post("/api/account/register", {
      username,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("restaurantid");
    localStorage.removeItem("accessToken");
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
