import * as React from "react";

import {
  Paper,
  Container,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Scrollbar from "./../../components/Scrollbar";

export default function IssueTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //   const handleRequestSort = (event, property) => {
  //     const isAsc = orderBy === property && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(property);
  //   };

  //   const handleSelectAllClick = (event) => {
  //     if (event.target.checked) {
  //       const newSelected = rows.map((n) => n.id);
  //       setSelected(newSelected);
  //       return;
  //     }
  //     setSelected([]);
  //   };

  //   const handleClick = (event, name) => {
  //     const selectedIndex = selected.indexOf(name);
  //     let newSelected = [];

  //     if (selectedIndex === -1) {
  //       newSelected = newSelected.concat(selected, name);
  //     } else if (selectedIndex === 0) {
  //       newSelected = newSelected.concat(selected.slice(1));
  //     } else if (selectedIndex === selected.length - 1) {
  //       newSelected = newSelected.concat(selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //       newSelected = newSelected.concat(
  //         selected.slice(0, selectedIndex),
  //         selected.slice(selectedIndex + 1)
  //       );
  //     }

  //     setSelected(newSelected);
  //   };

  //   const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  //   const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  //   };

  //   const isSelected = (name) => selected.indexOf(name) !== -1;

  //   // Avoid a layout jump when reaching the last page with empty rows.
  //   const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <div>
      <Paper sx={{ margin: "20px", padding: "15px" }}>
        <CardHeader title="Ticketing Issues" sx={{ mb: 3 }} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ticketing Number</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Collection Agent</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Ticket Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
                <TableRow></TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow>
                    <TableCell>{row.ticket_no}</TableCell>
                    <TableCell>{row.order_no}</TableCell>
                    <TableCell>{row.collection_agent}</TableCell>
                    <TableCell>{row.created_at}</TableCell>
                    <TableCell>{row.ticket_name}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell align="right">
                      <Link
                        to={`/dashboard/issues/${row.order_no}`}
                        state={{ ticket: row }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button>Details</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
    </div>
  );
}
