import PropTypes from "prop-types";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack } from "@mui/material";
// utils
import { fNumber, fPercent } from "../../utils/formatNumber";
// components
import Iconify from "../../components/Iconify";
import { BaseOptionChart } from "../../components/chart";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  marginBlockEnd: theme.spacing(1),
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

WidgetSummary.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
  title: PropTypes.string,
  total: PropTypes.number,
  stotal: PropTypes.number,
};

export default function WidgetSummary({
  title,
  total,
  stotal,
  icon,
  color = "primary",
  percentage,
  percentagefrom,
}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        minHeight: 200,
        width: 200,
      }}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundColor: (theme) => alpha(theme.palette[color].main, 0.16),
        }}
      >
        <Iconify icon={icon} />
      </IconWrapperStyle>

      {stotal && total ? (
        <Typography variant="h3" align="center" gutterBottom>
          {fNumber(total)} / {fNumber(stotal)}
        </Typography>
      ) : (
        <Typography variant="h3" align="center" gutterBottom>
          {Math.abs(
            parseFloat(total)
              .toFixed(2)
              .replace(/\.0*$|(\.\d*[1-9])0+$/, "$1")
          )}
        </Typography>
      )}
      <Typography variant="subtitle2" align="center">
        {title}
      </Typography>
      {percentage && (
        <Typography variant="h5" align="center" gutterBottom>
          {((parseInt(total) * 100) / parseInt(percentage)).toFixed(2)}%
        </Typography>
      )}
      {percentagefrom && (
        <Typography variant="subtitle2" align="center" gutterBottom>
          of {percentagefrom}
        </Typography>
      )}
    </Card>
  );
}
