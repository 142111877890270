import React from 'react'
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTheme } from '@mui/material/styles';

import _ from "lodash";
import DatePicker from "@mui/lab/DatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Divider, InputAdornment, MenuItem, Paper, Typography, Table, TableBody, TableContainer, TableSortLabel, TablePagination, TextField, TableRow, TableCell, TableHead, Checkbox, IconButton, OutlinedInput } from "@mui/material";
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from "@mui/material/InputLabel";
import ListItemText from '@mui/material/ListItemText';
import moment from "moment";
import { useEffect, useState } from "react";
import "../../styles/dataGrid.css";
import Iconify from '../Iconify';
import { GetGridata } from 'src/service/api';
import { visuallyHidden } from '@mui/utils';
import { _invoices } from '../../_mock/_invoice';
import { truncate } from 'lodash';
import { CSVLink } from "react-csv";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Person } from '@mui/icons-material';
import Label from '../Label';
import MetricsCards from './MetricsCards';

function DataGrid({ page, columnArr }) {

    const [fromDate, setFromDate] = useState(moment().subtract(7, 'd').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [ButtonDate, setButtonDate] = useState(null);
    const [filter, setFilter] = useState("");
    const [filterName, setFilterName] = useState("");
    const [sort, setSort] = useState('asc');
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sortField, setSortField] = useState("")
    const [selected, setSelected] = useState([]);
    const [total, setTotal] = useState(0);
    const [analysisReport, setAnalysisReport] = useState(null);
    const [timer, setTimer] = useState("");
    // console.log(_invoices)

    const [data, setData] = useState([])
    const [column, setColumn] = useState([])
    const [sortOrder, setSortOrder] = useState([])
    // const prevColumn = usePrevious(column)
    const theme = useTheme();

    const containerNameMapping ={
        "5 CP plates":"5 CP plates",
        "3 CP plates":"3 CP plates",
        "100ml (Circular)":"100ml (C)",
        "250ml (Rectangular)":"250ml (R)",
        "500ml (Rectangular)":"500ml (R)",
        "600ml (Rectangular)":"600ml (R)",
        "800ml (Rectangular)":"800ml (R)"
    } 

    console.log(pageSize)
    let TABLE_HEAD = []
    const onSort = (el) => {
        if (el !== "") {
            let isAsc = false;
            if (sort === 'asc') {
                isAsc = true;
            }
            setSort(isAsc ? 'desc' : 'asc');
            setSortField(el);
        }
    };

    const getWeekly = () => {
        setFilter("");
        setFilterName("");
        setPageNumber(1);
        setPageSize(10);
        setToDate(moment().format('YYYY-MM-DD'))
        setFromDate(moment().subtract(7, 'd').format('YYYY-MM-DD'))
        setButtonDate(`${moment().subtract(7, 'd').format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`)
    }

    const getDaily = () => {
        setFilter("");
        setFilterName("");
        setPageSize(10);
        setPageNumber(1);
        setFromDate(moment().format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setButtonDate(`${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`)
    }


    const handleColumnChange = async (event) => {
        // console.log(event)
        const value = event.target.value
        // const {
        //     target: { value },
        // } = event;
        // console.log({"value":event.target.value })

        // // setColumn(
        // //     typeof value === 'string' ? value.split(',') : value,
        console.log({ "prev": sortOrder })
        console.log({ "vaal": value })
        // // );
        const sorter = (a, b) => {
            return sortOrder.indexOf(a) - sortOrder.indexOf(b)
        };
        value.sort(sorter);

        console.log({ "vaal": value })
        // console.log({ "arr": arr })


        // console.log(arr)
        setColumn(value)
        console.log({ "col": column })

    }

    const onChangePage = (event, newPage) => {
        setPageNumber((initial) => initial + 1);
    };

    const onChangeRowsPerPage = (event) => {
        setPageSize(event.target.value)
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                console.log("init")
                const date = (toDate.length > 0 && fromDate.length > 0) ? `${fromDate}:${toDate}` : ""
                console.log({ "date": date })
                let res = await GetGridata(filter, filterName, pageNumber, pageSize, sortField, sort, date, page);
                console.log({ "resuse": res })
                console.log({ "page": page })
                if (page === "weeklyReport") {
                    setData(res.result.rows)
                    setTotal(res.result.total)
                    setAnalysisReport(res.analysisReport)
                    setSortOrder(Object.keys(res.result.rows[0]))
                    columnArr ? setColumn(columnArr) : setColumn(Object.keys(res.result.rows[0]))
                } else {
                    setData(res.rows)
                    setTotal(res.total)
                    setSortOrder(Object.keys(res.rows[0]))
                    columnArr ? setColumn(columnArr) : setColumn(Object.keys(res.rows[0]))
                }

            } catch (error) {
                console.log(error.message)

            }
        }
        fetch()
        setTimer(moment().format('h:mm a'));

    }, [])




    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        console.log({ "filterName": filterName })
    };

    const handleKeypress = (e) => {
        if (e.keyCode === 13) {
            loadData("search");
        }
    }
    const loadData = async (from) => {
        try {
            let oldDate = `${fromDate}:${toDate}`;
            console.log({ "old": oldDate })
            let date = `${fromDate}:${toDate}`
            if (from === "button") {
                date = ButtonDate
                console.log({ "changed": date })
            }
            console.log({ "date/unchanged": date })
            if (date && date.length > 0) {
                console.log({ "yes": date })
                let res = await GetGridata(filter, filterName, pageNumber, pageSize, sortField, sort, date, page);
                if (page === "weeklyReport") {
                    setData(res.result.rows)
                    setTotal(res.result.total)
                    setAnalysisReport(res.analysisReport)
                    if (column.length === 0) {
                        columnArr ? setColumn(columnArr) : setColumn(Object.keys(res.result.rows[0]))
                    }
                    if (sortOrder.length === 0) {
                        setSortOrder(Object.keys(res.result.rows[0]))

                    }
                } else {
                    setData(res.rows)
                    setTotal(res.total)
                    if (column.length === 0) {
                        columnArr ? setColumn(columnArr) : setColumn(Object.keys(res.rows[0]))
                    }
                    if (sortOrder.length === 0) {
                        setSortOrder(Object.keys(res.rows[0]))

                    }

                }
                console.log({ "res": res })
            }
            setTimer(moment().format('h:mm a'));

        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        loadData("search")
        console.log("search")
    }, [pageNumber, pageSize, sortField, sort])

    useEffect(() => {
        loadData("button")
        console.log("button")
    }, [ButtonDate])



    return (
        <Paper className="main-div">
            <div style={{ flex: 1, flexDirection: "row", alignSelf: "flex-end", justifyContent: "flex-end", marginBottom :'2rem' }}>
                <div>{`Last synced at: ${timer}`}</div>
            </div>
            <Paper elevation={10} className="search-bar" >
                <div className='filterline1' >

                    <LocalizationProvider className="from-date" dateAdapter={AdapterDateFns} >
                        <DesktopDatePicker
                            onKeyPress={handleKeypress}
                            label="From Date"
                            inputFormat="dd/MM/yyyy"
                            value={fromDate}
                            onChange={(newValue) => {
                                const date = moment(newValue).format("YYYY-MM-DD");

                                setFromDate(date);
                            }}
                            renderInput={(params) => <TextField size={'small'} {...params} />}
                        />
                    </LocalizationProvider>
                    <Divider className='divider'
                        style={{ height: "3vw" }}
                        orientation="vertical"
                    />
                    <LocalizationProvider  className="from-date" dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="To Date"
                            inputFormat="dd/MM/yyyy"
                            value={toDate}
                            onChange={(newValue) => {
                                const date = moment(newValue).format("YYYY-MM-DD");
                                setToDate(date);
                            }}
                            renderInput={(params) => <TextField size={'small'} {...params} />}
                        />
                    </LocalizationProvider>
                    <Divider className='divider'
                        style={{ height: "3vw" }}
                        orientation="vertical"
                        flexItem
                    />
                    <div className="filter-bar">
                        <FormControl className="filter-bar-form" size={'small'} >
                            <InputLabel
                                style={{ margin: "0rem 0.5vw" }}
                                id="demo-simple-select-label"
                            >
                                Filter By
                            </InputLabel>
                            <Select 
                                labelId="demo-simple-select-label"
                                style={{minWidth:"10rem"}}
                                label="Filter By"
                                disabled={column === null || column.length === 0}
                                value={(filter === null || filter.length === 0) ? "" : filter}
                                onChange={(e) => setFilter(e.target.value)}
                            >
                                {column !== null && column.length !== 0 && column.map((el, idx) => (
                                    <MenuItem key={idx} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Divider className='divider'
                        style={{ margin: "0rem 0.5vw", height: "3vw" }}
                        orientation="vertical"
                        flexItem
                    />

                    <div className="filter-bar">
                        <TextField
                            size={'small'}
                            style={{minWidth:"10rem"}}
                            labelId="demo-simple-select-label"
                            label="Filter"
                            fullWidth
                            disabled={filter === null || filter.length === 0}
                            value={filterName && filterName.length ? filterName : ""}
                            onChange={(event) => handleFilterName(event.target.value.toString())}
                            placeholder="Search"

                        />
                    </div>


                    <Divider className='divider'
                        style={{ margin: "0rem 0.5vw", height: "3vw" }}
                        orientation="vertical"
                        flexItem
                    />
                    <FormControl size={'small'} sx={{width:'15rem', marginRight:'1vw'}}>
                        <InputLabel id="demo-multiple-checkbox-label">Column</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={column}
                            onChange={handleColumnChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {data !== null && data.length !== 0 && Object.keys(data[0]).map((name) => {
                                // console.log({ "inex": column.indexOf(name) })
                                return (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={column.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                )
                            })}
                        </Select>

                    </FormControl>

                </div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "flex-end",paddingBottom:'1rem', justifyContent: "space-evenly", width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent:"center", alignItems:'center'  }}>
                        <CSVLink data={data}>
                            <IconButton color="primary">
                                <Iconify style={{ height: "2vw", width: "2vw" }} icon={'eva:download-outline'} />
                            </IconButton>
                        </CSVLink>
                        <Typography style={{ fontSize: '1vw', color: '#00AB55' }}>Download CSV</Typography>
                    </div>
                    <Button style={{ height: "3vw", width: "15vw", color: "#005400", fontSize: "1vw"}} onClick={getWeekly} variant="contained">Last week's Data</Button>
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent:"center", alignItems:'center' }}>

                        <IconButton color="primary"
                            onClick={loadData}
                        >
                            <SearchIcon style={{ height: "3vw", width: "3vw" }} />
                        </IconButton>
                        <Typography style={{ fontSize: '1vw', color: '#00AB55', fontWeight: 'bolder' }}>Click here to search</Typography>
                    </div>
                    <Button onClick={getDaily} style={{ height: "3vw", width: "15vw", color: "#005400", fontSize: "1vw" }} variant="contained">Today's data</Button>
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent:"center", alignItems:'center' }}>
                        <IconButton color="primary" onClick={getWeekly} >
                            <RestartAltIcon style={{ height: "2vw", width: "2vw" }} />
                        </IconButton>
                        <Typography style={{ fontSize: '1vw', color: '#00AB55' }}>Reset</Typography>
                    </div>
                </div>

            </Paper>
            {page === "weeklyReport" &&
                <MetricsCards analysis={analysisReport} page={page} />
            }
            <Paper style={{ elevation: 10 }}>
                <TableContainer sx={{ marginTop: "2vh",  overflow: "scroll", width:'80vw' }}>
                    {data !== null && data.length !== 0 &&
                        <Table sx={{
                            borderRadius: "20rem"
                        }}>
                            {/* <TableHeadCustom
                            order={sort}
                            orderBy={filterName}
                            headLabel={TABLE_HEAD}
                            rowCount={data.length}
                            numSelected={selected.length}
                            onSort={onSort}
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                    data.map((row) => row.order_id)
                                )
                            }
                        /> */}
                            <TableHead>
                                <TableRow style={{ overflowX: 'auto'}}>

                                    {column.map((el, idx) => (

                                        <TableCell

                                            className='table-head'
                                            sx={{
                                                px: 0,
                                                mx: 0
                                            }}
                                            align="center"
                                            key={idx}
                                        >
                                            <TableSortLabel
                                                active={sortField === el}
                                                direction={sortField === el ? sort : 'asc'}
                                                onClick={() => onSort(el)}
                                                sx={{ textTransform: 'capitalize' }}
                                            >
                                                {el !== null ? _.camelCase(el).replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5') : "-"}
                                            </TableSortLabel>
                                            {sortField === el ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {sort === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}

                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow style={{ overflowX: 'auto' }} key={index} hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {Object.entries(row).map((el, idx) => {
                                            if (column.find(element => element === el[0])) {
                                                if (el[0] === "collected_at" || el[0] === "scheduled_at") {
                                                    return (
                                                        <TableCell style={{ fontSize: 14, fontWeight: "500" }}
                                                            align="center"
                                                            key={idx} sx={{
                                                                px: 0,
                                                                mx: 0
                                                            }}>
                                                            {el[1] && moment(el[1]).format('MM/DD/YYYY, h:mm a')}
                                                        </TableCell>
                                                    )
                                                }else if (el[0] === "container_type"
                                                ) {
                                                    return (
                                                        <TableCell style={{ fontSize: 14, fontWeight: "500" }}
                                                            align="center"
                                                            key={idx} sx={{
                                                                px: 0,
                                                                mx: 0
                                                            }}>
                                                            {el[1] && containerNameMapping[el[1]]}
                                                        </TableCell>

                                                    )
                                                } else if (el[0] === "order_status") {
                                                    return (
                                                        <TableCell align="center" key={idx} >
                                                            <Label
                                                                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                                color={
                                                                    (el[1] === 'With Collection Agent' && 'primary') ||
                                                                    (el[1] === 'Available' && 'info') ||
                                                                    (el[1] === " With Customer" && 'success') ||
                                                                    (el[1] === 'Finalized' && 'success') ||
                                                                    (el[1] === 'In Progress' && 'warning') ||
                                                                    (el[1] === 'Cancelled' && 'error') ||
                                                                    'error'
                                                                }
                                                            >
                                                                {el[1]}
                                                            </Label>
                                                        </TableCell>

                                                    )
                                                } else {
                                                    return (
                                                        <TableCell
                                                            sx={{
                                                                px: 0,
                                                                mx: 0
                                                            }}
                                                            style={{ fontSize: 14, fontWeight: "500" }}
                                                            align="center"
                                                            key={idx}
                                                        >
                                                            {truncate(el[1], { 'length': 40 })}
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                        })}
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    }
                </TableContainer>
            </Paper>


            {data !== null && data.length !== 0 && <Box sx={{ position: 'relative' }}>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, total]}
                    component="div"
                    count={total}
                    rowsPerPage={pageSize}
                    page={pageNumber - 1}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Box>}

        </Paper>
    )
}

export default DataGrid