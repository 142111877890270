import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

function ContainersToCollect() {
  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );

  return (
    <>
      {!isLoading && (
        <Box m={3} p={1}>
          <Typography variant="h5" component="h2" style={{ marginBottom: 10 }}>
            {data.containersToBeCollected.length} Container's to Collect
          </Typography>
          <Grid container spacing={1} sx={{ border: 1 }}>
            {!isLoading &&
              data.containersToBeCollected.map((row) => (
                <Box
                  sx={{ border: 1, borderRadius: "16px", borderColor: "red" }}
                  m={2}
                  p={1}
                >
                  {row}
                </Box>
              ))}
          </Grid>
        </Box>
      )}

      {!isLoading && (
        <Box
          m={3}
          display="flex"
          justifyContent="flex-end"
          alignItem="flex-end"
        >
          <Button variant="outlined">Scan Containers</Button>
        </Box>
      )}
    </>
  );
}

export default ContainersToCollect;
