import merge from "lodash/merge";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, TextField, Table } from "@mui/material";
// components
import { BaseOptionChart } from "../../../../components/chart";
import {
  allContainer,
  getRestaurantContainer,
  minContainer,
} from "src/service/api";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Label from "src/components/Label";

import useInterval from "src/hooks/useInterval";

export default function ContainerStatistics({ tableData }) {
  //const [seriesData, setSeriesData] = useState('Year');
  // ['5 CP Plates', '250 ml Rectangular', '500 ml (Rectangular)', '600 ml (Rectangular)', '700 ml (Circular)', '750 ml (Circular)', '800 ml (Rectangular)', '100 ml (Rectangular)', '1000 ml (Rectangular)', '500 ml (Circular)']
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [cat, setCat] = useState([]);
  //   const [tableData, setTableData] = useState([]);

  //   useEffect(async () => {
  //     const categ = [];
  //     const cont = [];
  //     let totalCo = await allContainer();
  //     for (let i = 0; i < totalCo.length; i++) {
  //       categ.push(totalCo[i].ContainerName);
  //       cont.push(totalCo[i].TotalContainers);
  //     }
  //     setTotal(cont);
  //     setCat(categ);
  //     setLoading(false);
  //   }, []);

  //   useInterval(() => {
  //     fetchData();
  //   }, 10000);

  // const headers = ["Chinese Bae", "Homely", "Salad Studio", "Sarjapur Social"];

  // const CHART_DATA = [
  //     {
  //         year: 'Month',
  //         data: [
  //             { name: 'Total Containers', data: total },
  //             { name: 'Minimum Inventory', data: minsku },
  //         ],
  //     },
  // ];
  // const chartOptions = merge(BaseOptionChart(), {
  //     stroke: {
  //         show: true,
  //         width: 2,
  //         colors: ['transparent'],
  //     },
  //     xaxis: {
  //         categories: cat,
  //     },
  //     tooltip: {
  //         y: {
  //             formatter: (val) => `${val}`,
  //         },
  //     },
  // });

  return (
    <div>
      <TableContainer
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "2rem",
        }}
        component={Paper}
      >
        <Table
          sx={{
            width: "100rem",
            margin: "1rem",
            paddingBottom: "6rem",
            borderRadius: "2rem",
          }}
          aria-label="simple table"
        >
          <TableHead style={{ borderRadius: "3rem", elevation: 10 }}>
            <TableRow>
              <TableCell />
              {tableData.length !== 0 &&
                tableData.restaurantList.map((header, id) => (
                  <TableCell
                    style={{ fontSize: "1.2rem", fontWeight: "600" }}
                    key={id}
                    align="center"
                  >
                    {header}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ borderRadius: "0rem 0rem 10rem 10rem" }}>
            {tableData.length !== 0 &&
              tableData.data.map((data, id) => (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {data.map((cell, id) => (
                    <TableCell
                      style={{ fontSize: "1.2rem", fontWeight: "600" }}
                      key={id}
                      align="center"
                      component="th"
                      scope="row"
                    >
                      <Label
                        //   variant={
                        //     theme.palette.mode === "light" ? "ghost" : "filled"
                        //   }
                        color={
                          (cell <= 15 && "error") ||
                          (cell <= 30 && "warning") ||
                          (cell == "-" && "default") ||
                          "success"
                        }
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: "600",
                          textTransform: "capitalize",
                        }}
                      >
                        {cell}
                      </Label>
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{ fontSize: "1.2rem", fontWeight: "600" }}
                align="center"
                component="th"
                scope="row"
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
