import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/Iconify';
import { truncate } from 'lodash';
import { useTheme } from "@mui/material/styles";


// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  height: '20vw',
  width: '12vw',
  padding: '1vw',
  marginBottom: '2vw',
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.down('md')]: {
    height: '32vw',
    width: '16vw',
  },
  [theme.breakpoints.only('md')]: {
    height: '25vw',
    width: '30w',
    marginRight: '1vw',
  },

}));
const RootStyle = styled(Card)(({ theme }) => ({
  margin: "auto",
  boxShadow: 'none',
  display: 'flex',
  flexDirection: "column",
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  justifySelf: 'center',
  height: "75%",
  alignSelf: 'center'

}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: "auto",
  borderRadius: '50%',
  alignItems: 'center',
  width: '3vw',
  height: '3vw',
  margingBottom: '1vw',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '6vw',
    height: '6vw',
    margingBottom: '2vw',
  },
}));
const HeadingStyle = styled('Typography')(({ theme }) => ({
  fontSize: '2vw',
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: '3.2vw',
  },
  [theme.breakpoints.only('md')]: {
    fontSize: '2.8vw',
  },
}));
const TextStyle = styled('Typography')(({ theme }) => ({
  fontSize: '1vw',
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.7vw',
  },
  [theme.breakpoints.only('md')]: {
    fontSize: '1.3vw',
  },
}));

// ----------------------------------------------------------------------

AnalyticsWidgetSummary.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error']),
  icon: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function AnalyticsWidgetSummary({ title, total, subTitle, subText, icon, color = 'primary' }) {
  const theme = useTheme();
  return (
    <CardStyle>
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={'2vw'} height={'2vw'} />
      </IconWrapperStyle>
      <RootStyle sx={{
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
      }}>
        <HeadingStyle variant="h4">{fShortenNumber(total)}</HeadingStyle>
        <TextStyle style={{ marginBottom: 8 }} variant="subtitle2" sx={{ opacity: 0.85 }}>
          {title}
        </TextStyle>
        {subTitle && subTitle >= 0 && <HeadingStyle style={{}} variant="h4" sx={{ opacity: 0.85 }}>
          {`${truncate(subTitle, { 'length': 5, 'omission': '' })}%`}
        </HeadingStyle>}
        {subText && <TextStyle style={{}} variant="subtitle2" sx={{ opacity: 0.72 }}>
          {subText}
        </TextStyle>}

      </RootStyle>
    </CardStyle>
  );
}
