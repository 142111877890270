import React, { useState, useEffect } from "react";
import {
  Paper,
  Container,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { useSelector, useDispatch } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { collectionReport } from "src/redux/slices/collectionAgentReportState";
import SearchIcon from "@mui/icons-material/Search";

function LoadShareTable({ agent, fromDate, toDate }) {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );

  useEffect(() => {
    setLoading(true);
    const collectionDataResponse = async () =>
      await dispatch(
        collectionReport(
          "All",
          moment().subtract(7, "d").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          localStorage.getItem("userTypeId"),
          moment().format("YYYY-MM-DD")
        )
      );
    collectionDataResponse();
    setLoading(false);
  }, []);

  let localAgent = "All";

  if (agent !== "") {
    localAgent = agent;
  }

  const getLoadShareInfo = async () => {
    console.log("WHAT IS AGENT", localAgent);
    setLoading(true);
    const collectionDataResponse = await dispatch(
      collectionReport(
        localAgent,
        fromDate,
        toDate,
        localStorage.getItem("userTypeId"),
        date
      )
    );
    setLoading(false);
  };

  return (
    <div>
      {!isLoading ? (
        <Paper sx={{ margin: "10px", padding: "15px" }}>
          <CardHeader title="LoadShare Details" sx={{ mb: 3 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LocalizationProvider
              className="from-date"
              dateAdapter={AdapterDateFns}
            >
              <DesktopDatePicker
                sx={{ marginLeft: "10px" }}
                label="Date"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(newValue) => {
                  const today = moment(newValue).format("YYYY-MM-DD");

                  setDate(today);
                }}
                renderInput={(params) => (
                  <TextField size={"small"} {...params} />
                )}
              />
            </LocalizationProvider>
            <Divider
              className="divider"
              style={{ margin: 5 }}
              orientation="vertical"
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 150,
              }}
            >
              <Button
                className="searchButton"
                style={{ margin: 5 }}
                onClick={getLoadShareInfo}
              >
                <SearchIcon className="searchButtonIcon" />
              </Button>
              <Typography style={{ fontSize: 11 }}>
                Click here to search
              </Typography>
            </div>
          </div>

          {!loading && (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 720, marginTop: "1rem" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl no.</TableCell>
                      <TableCell>Collection Agent</TableCell>

                      <TableCell>Assigned Orders</TableCell>
                      <TableCell>Collected Orders</TableCell>
                      <TableCell>Not Collected</TableCell>
                      <TableCell>Ticket Created</TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                  </TableHead>
                  <TableBody>
                    {data.loadShareCollectionDetails.map(
                      (collection, index) => (
                        <TableRow>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell>{collection.username}</TableCell>
                          <TableCell>{collection.totalAssigned}</TableCell>
                          <TableCell>{collection.collected}</TableCell>
                          <TableCell>{collection.toBeCollected}</TableCell>
                          <TableCell>{collection.ticketCreated}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}
        </Paper>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={100}
            size={56}
            thickness={4}
            sx={{ position: "absolute", top: "50%", left: "60%" }}
            disableShrink
          />
        </Box>
      )}
    </div>
  );
}

export default LoadShareTable;
